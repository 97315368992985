import React, { Component } from 'react';
import { Button, Form, Header, Icon, Modal } from 'semantic-ui-react';

import API from '../services/api.js';

const optionsAmount = [
	{
		key: 'value',
		text: 'USDT',
		value: 'value'
	},
	{
		key: 'percent',
		text: '%',
		value: 'percent'
	},
];

 const optionsOnOff = [
	{
		key: '0',
		text: 'OFF',
		value: '0'
	},
	{
		key: '1',
		text: 'ON',
		value: '1'
	},
];

const optionsYesNo = [
	{
		key: '0',
		text: 'No',
		value: '0'
	},
	{
		key: '1',
		text: 'Yes',
		value: '1'
	},
];

const optionsLeverageMode = [
	{
		key: '0',
		text: 'Cross',
		value: '0'
	},
	{
		key: '1',
		text: 'Isolate',
		value: '1'
	},
];

const optionsOrderType = [
	{
		key: 'limit',
		text: 'Limit',
		value: 'limit'
	},
	{
		key: 'Market',
		text: 'Market',
		value: 'Market'
	},
];

const optionsPriceType = [
	{
		key: 'MarkPrice',
		text: 'MarkPrice',
		value: 'MarkPrice'
	},
	{
		key: 'LastPrice',
		text: 'LastPrice',
		value: 'LastPrice'
	},
];

const optionsOneway = [
	{
		key: 'no',
		text: 'NO',
		value: ''
	},
	{
		key: 'sell',
		text: 'SELL',
		value: 'SELL'
	},
	{
		key: 'buy',
		text: 'BUY',
		value: 'BUY'
	},
];

const keys = {
	"SYMBOL_ONEWAY_ORDER": "Oneway",
	"SYMBOL_LEVERAGE_LEVEL": "Leverage level",
	"SYMBOL_LEVERAGE_MODE": "Leverage mode",
	"POSITION_CHECK_INTERVAL": "Check in",
	"POSITION_OPEN_AGAIN": "Open again",
	"POSITION_CLOSE_BY_PNL": "P&L close",
	"POSITION_CLOSE_BY_PNL_IN": "P&L close in",
	"POSITION_CLOSE_PNL": "P&L close value",
	"POSITION_CLOSE_TIME": "P&L close time",
	"ORDER_TYPE": "Order Type",
	"ORDER_PRICE": "Price Type",
	"ORDER_AMOUNT_TO_TRADE_IN": "Deposit in",
	"ORDER_AMOUNT_TO_STOP_TRADE_IN": "TP & SL in",
	"ORDER_AMOUNT_TO_STOP_TRADE_TP_IN": "TP in",
	"ORDER_AMOUNT_TO_STOP_TRADE_SL_IN": "SL in",
	"ORDER_AMOUNT_TO_TRADE": "Deposit",
	"ORDER_AMOUNT_TO_STOP_TRADE_TP": "Set TP",
	"ORDER_AMOUNT_TO_STOP_TRADE_SL": "Set SL",
	"ORDER_DELAY": "Order delay",
	"SYMBOL": "Symbol",

	"HACK_PNL_FOR_SET_SL": "Once P&L",
	"HACK_PNL_FOR_SET_SL_IN": "Once P&L in",
	"HACK_SET_SL": "Once SL",
	"HACK_SET_SL_IN": "Once SL in",
	"HACK_SET_SL_ON": "Once Set SL",

	"HACK_REC_PNL_FOR_SET_SL": "Rise P&L",
	"HACK_REC_PNL_FOR_SET_SL_IN": "Rise P&L in",
	"HACK_REC_SET_SL": "Rise SL",
	"HACK_REC_SET_SL_IN": "Rise SL in",
	"HACK_REC_SET_SL_ON": "Rise SL",
}

class FormConfig extends Component {

	constructor (props) {
		super(props);

		this.state = {
			config: props.config
		};

		this.closeModal = props.closeModal;
	}

	saveConfig = async () => {
		try {
			await API.Config(this.state.config.SYMBOL, this.state.config);
			this.closeModal();
		} catch (err) {
			console.error(err);
			window.confirm('Check data for pair!');
		}
	}

	handleChange = (key) => (e, { value }) => {
		this.setState({ config: { ...this.state.config, [key]: value} });
	}

	render () {
		const { config } = this.state;
		return (<>
				<Header icon="archive" content="Symbol config"/>
				<Modal.Content>
					<Form>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['SYMBOL']} placeholder="USDT" value={config['SYMBOL']} onChange={this.handleChange('SYMBOL')}/>
							<Form.Select
								fluid
								label={keys['SYMBOL_ONEWAY_ORDER']}
								options={optionsOneway}
								value={config['SYMBOL_ONEWAY_ORDER']}
								onChange={this.handleChange('SYMBOL_ONEWAY_ORDER')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['SYMBOL_LEVERAGE_LEVEL']} placeholder="10" type={"number"} value={config['SYMBOL_LEVERAGE_LEVEL']} onChange={this.handleChange('SYMBOL_LEVERAGE_LEVEL')}/>
							<Form.Select
								fluid
								label={keys['SYMBOL_LEVERAGE_MODE']}
								options={optionsLeverageMode}
								value={config['SYMBOL_LEVERAGE_MODE']}
								onChange={this.handleChange('SYMBOL_LEVERAGE_MODE')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['POSITION_CHECK_INTERVAL']} placeholder="10" type={"number"} value={config['POSITION_CHECK_INTERVAL']} onChange={this.handleChange('POSITION_CHECK_INTERVAL')}/>
							<Form.Select
								fluid
								label={keys['POSITION_OPEN_AGAIN']}
								options={optionsYesNo}
								value={config['POSITION_OPEN_AGAIN']}
								onChange={this.handleChange('POSITION_OPEN_AGAIN')}
							/>
							<Form.Select
								fluid
								label={keys['ORDER_DELAY']}
								options={optionsYesNo}
								value={config['ORDER_DELAY']}
								onChange={this.handleChange('ORDER_DELAY')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['POSITION_CLOSE_PNL']} placeholder="10" type={"number"} value={config['POSITION_CLOSE_PNL']} onChange={this.handleChange('POSITION_CLOSE_PNL')}/>
							<Form.Select
								fluid
								label={keys['POSITION_CLOSE_BY_PNL_IN']}
								options={optionsAmount}
								value={config['POSITION_CLOSE_BY_PNL_IN']}
								onChange={this.handleChange('POSITION_CLOSE_BY_PNL_IN')}
							/>
							<Form.Input fluid label={keys['POSITION_CLOSE_TIME']} placeholder="10" type={"number"} value={config['POSITION_CLOSE_TIME']} onChange={this.handleChange('POSITION_CLOSE_TIME')}/>
							<Form.Select
								fluid
								label={keys['POSITION_CLOSE_BY_PNL']}
								options={optionsYesNo}
								value={config['POSITION_CLOSE_BY_PNL']}
								onChange={this.handleChange('POSITION_CLOSE_BY_PNL')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['ORDER_AMOUNT_TO_TRADE']} placeholder="10" type={"number"} value={config['ORDER_AMOUNT_TO_TRADE']} onChange={this.handleChange('ORDER_AMOUNT_TO_TRADE')}/>
							<Form.Select
								fluid
								label={keys['ORDER_AMOUNT_TO_TRADE_IN']}
								options={optionsAmount}
								value={config['ORDER_AMOUNT_TO_TRADE_IN']}
								onChange={this.handleChange('ORDER_AMOUNT_TO_TRADE_IN')}
							/>
							<Form.Select
								fluid
								label={keys['ORDER_TYPE']}
								options={optionsOrderType}
								value={config['ORDER_TYPE']}
								onChange={this.handleChange('ORDER_TYPE')}
							/>
							<Form.Select
								fluid
								label={keys['ORDER_PRICE']}
								options={optionsPriceType}
								value={config['ORDER_PRICE']}
								onChange={this.handleChange('ORDER_PRICE')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['ORDER_AMOUNT_TO_STOP_TRADE_TP']} placeholder="10" type={"number"} value={config['ORDER_AMOUNT_TO_STOP_TRADE_TP']} onChange={this.handleChange('ORDER_AMOUNT_TO_STOP_TRADE_TP')}/>
							<Form.Select
								fluid
								label={keys['ORDER_AMOUNT_TO_STOP_TRADE_TP_IN']}
								options={optionsAmount}
								value={config['ORDER_AMOUNT_TO_STOP_TRADE_TP_IN']}
								onChange={this.handleChange('ORDER_AMOUNT_TO_STOP_TRADE_TP_IN')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['ORDER_AMOUNT_TO_STOP_TRADE_SL']} placeholder="10" type={"number"} value={config['ORDER_AMOUNT_TO_STOP_TRADE_SL']} onChange={this.handleChange('ORDER_AMOUNT_TO_STOP_TRADE_SL')}/>
							<Form.Select
								fluid
								label={keys['ORDER_AMOUNT_TO_STOP_TRADE_SL_IN']}
								options={optionsAmount}
								value={config['ORDER_AMOUNT_TO_STOP_TRADE_SL_IN']}
								onChange={this.handleChange('ORDER_AMOUNT_TO_STOP_TRADE_SL_IN')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['HACK_PNL_FOR_SET_SL']} placeholder="10" type={"number"} value={config['HACK_PNL_FOR_SET_SL']} onChange={this.handleChange('HACK_PNL_FOR_SET_SL')}/>
							<Form.Select
								fluid
								label={keys['HACK_PNL_FOR_SET_SL_IN']}
								options={optionsAmount}
								value={config['HACK_PNL_FOR_SET_SL_IN']}
								onChange={this.handleChange('HACK_PNL_FOR_SET_SL_IN')}
							/>
							<Form.Input fluid label={keys['HACK_SET_SL']} placeholder="10" type={"number"} value={config['HACK_SET_SL']} onChange={this.handleChange('HACK_SET_SL')}/>
							<Form.Select
								fluid
								label={keys['HACK_SET_SL_IN']}
								options={optionsAmount}
								value={config['HACK_SET_SL_IN']}
								onChange={this.handleChange('HACK_SET_SL_IN')}
							/><Form.Select
								fluid
								label={keys['HACK_SET_SL_ON']}
								options={optionsOnOff}
								value={config['HACK_SET_SL_ON']}
								onChange={this.handleChange('HACK_SET_SL_ON')}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input fluid label={keys['HACK_REC_PNL_FOR_SET_SL']} placeholder="10" type={"number"} value={config['HACK_REC_PNL_FOR_SET_SL']} onChange={this.handleChange('HACK_REC_PNL_FOR_SET_SL')}/>
							<Form.Select
								fluid
								label={keys['HACK_REC_PNL_FOR_SET_SL_IN']}
								options={optionsAmount}
								value={config['HACK_REC_PNL_FOR_SET_SL_IN']}
								onChange={this.handleChange('HACK_REC_PNL_FOR_SET_SL_IN')}
							/>
							<Form.Input fluid label={keys['HACK_REC_SET_SL']} placeholder="10" type={"number"} value={config['HACK_REC_SET_SL']} onChange={this.handleChange('HACK_REC_SET_SL')}/>
							<Form.Select
								fluid
								label={keys['HACK_REC_SET_SL_IN']}
								options={optionsAmount}
								value={config['HACK_REC_SET_SL_IN']}
								onChange={this.handleChange('HACK_REC_SET_SL_IN')}
							/><Form.Select
								fluid
								label={keys['HACK_REC_SET_SL_ON']}
								options={optionsOnOff}
								value={config['HACK_REC_SET_SL_ON']}
								onChange={this.handleChange('HACK_REC_SET_SL_ON')}
							/>
						</Form.Group>`
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button color="red" onClick={this.closeModal}>
						<Icon name="remove"/> Close
					</Button>
					<Button color="green" onClick={() => this.saveConfig(config)}>
						<Icon name="checkmark"/> Save
					</Button>
				</Modal.Actions>
			</>
		);
	}
}

export default FormConfig;
