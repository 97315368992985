import _ from 'lodash';

import React from 'react';
import { Card, Button, Statistic, Icon } from 'semantic-ui-react';

import Config from './Config.js';

import API from '../services/api.js';

function Symbol (props) {
	const {
		symbol,
		deleteSymbol
	} = props;

	//if(symbol && symbol.prevValue)  console.log(symbol.tick.lastPrice, symbol.prevValue.tick.lastPrice);

	const side = _.get(symbol, 'position.side', 'None') || 'None';
	const pair = _.get(symbol, 'config.SYMBOL', 'NO');
	const size = Number(_.get(symbol, 'position.size', '0.00'));
	const qty = Number(_.get(symbol, 'position.positionValue', '0.00'));
	const avgPrice = Number(_.get(symbol, 'position.avgPrice', '0.00'));
	const pnl = Number(_.get(symbol, 'position.unrealisedPnl', '0.00'));
	const pnll = Number(_.get(symbol, 'position.profitLastPrice', '0.00'));
	const prevPnl = Number(_.get(symbol, 'prevValue.position.unrealisedPnl', '0.00'));
	const prevPnll = Number(_.get(symbol, 'prevValue.position.profitLastPrice', '0.00'));
	const takeProfit = Number(_.get(symbol, 'position.takeProfit', '0.00'));
	const stopLoss = Number(_.get(symbol, 'position.stopLoss', '0.00'));
	const lastPrice = Number(_.get(symbol, 'tick.lastPrice', '0.00'));
	const prevLastPrice = Number(_.get(symbol, 'prevValue.tick.lastPrice', '0.00'));
	const markPrice = Number(_.get(symbol, 'tick.markPrice', '0.00'));
	const prevMarkPrice = Number(_.get(symbol, 'prevValue.tick.markPrice', '0.00'));

	let position_side_color = '';
	if (side === 'Buy') {
		position_side_color = 'green';
	} else if (side === 'Sell') {
		position_side_color = 'red';
	} else {
		position_side_color = 'black';
	}

	let compare = '=';
	if (lastPrice > markPrice) {
		compare = '>';
	} else if (lastPrice < markPrice) {
		compare = '<';
	}

	const close = () => {
		API.Position.close(symbol.config.SYMBOL)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const trend = (symbol, trend) => {
		// eslint-disable-next-line no-restricted-globals
		const side = confirm('Set trend: NO - SELL, OK - BUY');

		API.Trend.set(symbol, trend, side ? 'BUY' : 'SELL')
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const buy = () => {
		API.Order.buy(symbol.config.SYMBOL)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const sell = () => {
		API.Order.sell(symbol.config.SYMBOL)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const stop = () => {
		API.Symbol.stop(symbol.config.SYMBOL)
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	const del = () => {
		API.Symbol.delete(symbol.config.SYMBOL)
			.then(res => {
				deleteSymbol(symbol.config.SYMBOL);
				console.log(res);
			})
			.catch(err => {
				console.error(err);
			});
	};

	return (

		<Card key={`symbol_${pair}`} className={position_side_color}>
			<Card.Content>
				<Card.Header>
					<Statistic size="mini" color={position_side_color}>
						<Statistic.Value><Button icon="edit" compact onClick={() => props.selectConfig(symbol.config)}/>{`${pair} - ${size} (${qty})`}<Button icon="delete" compact onClick={del}/></Statistic.Value>
					</Statistic>
				</Card.Header>
				<Card.Meta style={{ fontSize: 18 }}><b>AVG</b>: {avgPrice}</Card.Meta>
				<Card.Description style={{
					fontSize: 18,
					color: (pnl < 0 ? 'red' : 'green')
				}}><b>P&L</b>: {pnl}<Icon name={(pnl > prevPnl ? 'arrow up' : (pnl < prevPnl ? 'arrow down' : ''))} size="small"/>({pnll}<Icon name={(pnll > prevPnll ? 'arrow up' : (pnll < prevPnll ? 'arrow down' : ''))} size="small"/>)</Card.Description>
				<Card.Description textAlign="center" style={{ fontSize: 18 }}>{stopLoss} :SL / TP: {takeProfit}</Card.Description>
				<Card.Description textAlign="center" style={{ fontSize: 18 }}><Icon name={(lastPrice > prevLastPrice ? 'arrow up' : (lastPrice < prevLastPrice ? 'arrow down' : ''))} size="small"/>{lastPrice} :LP {compare} MP: {markPrice}<Icon name={(markPrice > prevMarkPrice ? 'arrow up' : (markPrice < prevMarkPrice ? 'arrow down' : ''))} size="small"/></Card.Description>
			</Card.Content>
			<Card.Content extra>
				<Config setTrend={trend} trend={symbol.trend} config={symbol.config}/>
			</Card.Content>
			<Card.Content extra>
				<div className={`ui ${_.get(symbol, 'position.side', 'None') === 'None' ? 'two' : 'one'} buttons`}>
					{side !== 'None' ? (<Button compact onClick={close} color="blue">Close</Button>) : (<></>)}
					{side === 'None' ? (<Button compact onClick={buy} color="green">Buy</Button>) : (<></>)}
					{side === 'None' ? (<Button compact onClick={sell} color="red">Sell</Button>) : (<></>)}
				</div>
			</Card.Content>
			<Card.Content extra>
				<div className={`ui one buttons`}>
					<Button compact onClick={stop} color={symbol.stop ? 'green' : 'red'}>
						{symbol.stop ? 'Start' : 'Stop'}
					</Button>
				</div>
			</Card.Content>
		</Card>
	);
}

export default Symbol;
