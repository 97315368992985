import _ from 'lodash';

import React from 'react';
import { socket } from '../services/socket';
import { Card } from 'semantic-ui-react';

import Symbol from './Symbol.js';

class Symbols extends React.Component{
	constructor (props) {
		super(props);

		this.socket = socket;
		this.state = {
			symbols: [],
		};

		this.symbols = [];

		this.selectConfig = this.props.selectConfig;
	}

	deleteSymbol = (symbol) => {
		delete this.symbols[symbol];

		const symbols = _.sortBy(Object.values(this.symbols), 'config.SYMBOL');

		this.setState({
			symbols
		});
	}

	componentDidMount () {
		socket.on('symbol', (msg) => {
			const prevValue = _.omit({...this.symbols[msg.config.SYMBOL]}, 'prevValue');
			this.symbols[msg.config.SYMBOL] = msg;
			this.symbols[msg.config.SYMBOL].prevValue = prevValue;

			const symbols = _.sortBy(Object.values(this.symbols), 'config.SYMBOL');

			this.setState({
				symbols
			});
		});
		socket.on('delete', (msg) => {
			delete this.symbols[msg.symbol];
			const symbols = _.sortBy(Object.values(this.symbols), 'config.SYMBOL');

			this.setState({
				symbols
			});
		});
	}

	componentWillUnmount () {
		socket.off('symbol');
		socket.off('delete');
	}

	render () {
		const { symbols } = this.state;

		return (
			<Card.Group centered itemsPerRow={symbols.length}>
				{_.map(symbols, symbol => (<Symbol selectConfig={this.selectConfig} key={`symbol_${symbol.config.SYMBOL}`} symbol={symbol} deleteSymbol={this.deleteSymbol} />))}
			</Card.Group>
		)
	}
}

export default Symbols;

