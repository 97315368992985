import _ from 'lodash';
import axios from 'axios';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? 'https://api.cryptorabbit.pro' : 'http://localhost:3000';

axios.defaults.baseURL = `${URL}/api`;

const API = {
	Auth: {
		login: async (password) => {
			try {
				const response = await axios.post(`${URL}/auth`, {password});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		}
	},

	Position: {
		close: async (symbol) => {
			try {
				const response = await axios.post(`/position/${symbol}/close`,null,{
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		}
	},

	Order: {
		buy: async (symbol) => {
			try {
				const response = await axios.post(`/order/${symbol}/buy`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		},

		sell: async (symbol) => {
			try {
				const response = await axios.post(`/order/${symbol}/sell`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		},
	},

	Trend: {
		set: async (symbol, trend, side) => {
			try {
				const response = await axios.post(`/trend/${symbol}/${trend}/${side}`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		},
	},

	Symbol: {
		add: async (symbol) => {
			try {
				const response = await axios.post(`/symbol/${symbol}/add`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		},

		stop: async (symbol) => {
			try {
				const response = await axios.post(`/symbol/${symbol}/stop`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		},

		delete: async (symbol) => {
			try {
				const response = await axios.post(`/symbol/${symbol}/delete`, null, {
					headers: {
						Authorization: window.cryptoKey
					}
				});

				return response.data;
			} catch (err) {
				console.error(err);
			}
		}
	},

	Config: async (symbol, config) => {
		try {
			const response = await axios.put(`/config/${symbol}`, config, {
				headers: {
					Authorization: window.cryptoKey
				}
			});

			return response.data;
		} catch (err) {
			console.error(err);
			throw err;
		}
	},

	Trade: async () => {
		try {
			const amount = Number(prompt('Stop balance?', 25));

			const response = await axios.post(`/trade/${_.isNumber(amount) ? amount : 25}`, {}, {
				headers: {
					Authorization: window.cryptoKey
				}
			});

			return response.data;
		} catch (err) {
			console.error(err);
			throw err;
		}
	}
}


export default API;
