import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3000';

const socket = window.socket ? window.socket : (window.socket = io(URL, {
	autoConnect: false,
	auth: (cb) => {
		const token = window.cryptoKey;
		cb({
			token
		});
	}
}));

socket.on("connect_error", (err) => {
	console.error(err);
	socket.cryptoKeyNull();
});

export { socket };
export default socket;
