import React, { useState } from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';

const LoginForm = (props) => {
	const [value, setValue] = useState('');
	const login = () => {
		props.callback(value);
	}

	return (<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
		<Grid.Column style={{ maxWidth: 450 }}>
			<Header as='h2' color='teal' textAlign='center'>Log-in</Header>
			<Form size='large'>
				<Segment stacked>
					<Form.Input
						fluid
						icon='lock'
						iconPosition='left'
						placeholder='Password'
						type='password'
						value={value}
						onChange={(e, {value}) => setValue(value)}
					/>
					<Button onClick={login} color='teal' fluid size='large'>
						Login
					</Button>
				</Segment>
			</Form>
		</Grid.Column>
	</Grid>)
}

export default LoginForm;
