import _ from 'lodash';

import React from 'react';

import './App.css';

import API from './services/api.js';

import { Crypto } from './components';

import LoginForm from './components/Login';

import { socket } from './services/socket.js';

class App extends React.Component{
    constructor (props) {
        super(props);

        this.state = {
            login: false
        }
    }

    cryptoKeyNull = () => {
        window.cryptoKey = null;
        this.setState({
            login: false
        });
    }
    auth = (password) => {
        const self = this;

        API.Auth.login(password)
            .then(response => {
                window.cryptoKey = response;
                socket.connect();
                self.setState({
                    login: true
                });

            })
            .catch(err => {
                console.error(err);
                socket.disconnect();
                window.cryptoKey = null;
                self.setState({
                    login: false
                });
            })
    }
    render() {
        socket.cryptoKeyNull = this.cryptoKeyNull;

        if (_.isNil(window.cryptoKey) && !this.state.login) {
            return (
                <LoginForm callback={this.auth}/>
            )
        } else {
            socket.connect();
            return (
                <Crypto />
            );
        }
    }
}

export default App;
