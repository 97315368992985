import _ from 'lodash';

import React from 'react';

import { Button, Table } from 'semantic-ui-react';

const keys = {
	"SYMBOL_ONEWAY_ORDER": "Oneway",
	"SYMBOL_LEVERAGE_LEVEL": "Leverage l.",
	"SYMBOL_LEVERAGE_MODE": "Leverage m.",
	"POSITION_CHECK_INTERVAL": "Check in",
	"POSITION_OPEN_AGAIN": "Open again",
	"POSITION_CLOSE_BY_PNL": "P&L close",
	"POSITION_CLOSE_BY_PNL_IN": "P&L close in",
	"POSITION_CLOSE_PNL": "P&L close value",
	"POSITION_CLOSE_TIME": "P&L close time",
	"ORDER_TYPE": "Order Type",
	"ORDER_PRICE": "Price type",
	"ORDER_AMOUNT_TO_TRADE_IN": "Deposit in",
	"ORDER_AMOUNT_TO_STOP_TRADE_IN": undefined, // "TP & SL in",
	"ORDER_AMOUNT_TO_STOP_TRADE_TP_IN": "TP in",
	"ORDER_AMOUNT_TO_STOP_TRADE_SL_IN": "SL in",
	"ORDER_AMOUNT_TO_TRADE": "Deposit",
	"ORDER_AMOUNT_TO_STOP_TRADE_TP": "Set TP",
	"ORDER_AMOUNT_TO_STOP_TRADE_SL": "Set SL",
	"ORDER_DELAY": "Delay order",

	"SYMBOL": undefined,
	"TRADE_BALANCE_STOP": "Stop balance",

	"HACK_PNL_FOR_SET_SL": "Once P&L",
	"HACK_PNL_FOR_SET_SL_IN": "Once P&L in",
	"HACK_SET_SL": "Once SL",
	"HACK_SET_SL_IN": "Once SL in",
	"HACK_SET_SL_ON": "Once Set SL",
	"HACK_REC_PNL_FOR_SET_SL": "Rise P&L",
	"HACK_REC_PNL_FOR_SET_SL_IN": "Rise P&L in",
	"HACK_REC_SET_SL": "Rise SL",
	"HACK_REC_SET_SL_IN": "Rise SL in",
	"HACK_REC_SET_SL_ON": "Rise SL",
}

const values = {
	"SYMBOL_LEVERAGE_MODE": {
		"0": "cross",
		"1": "isolate"
	},
	"POSITION_OPEN_AGAIN": {
		"0": "OFF",
		"1": "ON"
	},
	"POSITION_CLOSE_BY_PNL_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"POSITION_CLOSE_BY_PNL": {
		"0": "OFF",
		"1": "ON"
	},
	"HACK_SET_SL_ON": {
		"0": "OFF",
		"1": "ON"
	},
	"HACK_REC_SET_SL_ON": {
		"0": "OFF",
		"1": "ON"
	},
	"ORDER_DELAY": {
		"0": "OFF",
		"1": "ON"
	},
	"ORDER_AMOUNT_TO_TRADE_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"ORDER_AMOUNT_TO_STOP_TRADE_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"ORDER_AMOUNT_TO_STOP_TRADE_TP_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"ORDER_AMOUNT_TO_STOP_TRADE_SL_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"HACK_SET_SL_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"HACK_PNL_FOR_SET_SL_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"HACK_REC_SET_SL_IN": {
		"value": "USDT",
		"percent": "%"
	},
	"HACK_REC_PNL_FOR_SET_SL_IN": {
		"value": "USDT",
		"percent": "%"
	}/*,
	"ORDER_AMOUNT_TO_STOP_TRADE_TP": {
		"0": "NO",
		"1": "YES"
	},
	"ORDER_AMOUNT_TO_STOP_TRADE_SL": {
		"0": "NO",
		"1": "YES"
	}*/
}

const hints = {
	"POSITION_CHECK_INTERVAL": " ms.",
	"POSITION_CLOSE_TIME": " sec.",
	"TRADE_BALANCE_STOP": " USDT",
}

function Config (props) {
	const { setTrend, config, trend } = props;

	return (
		<Table basic="very" celled collapsing>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Key</Table.HeaderCell>
					<Table.HeaderCell>Value</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row key={`config_trend_row`}>
					<Table.Cell key={`config_trend_cell_a`}>
						<Button compact onClick={() => setTrend(config.SYMBOL, 'A')} color={trend.A === 'BUY' ? 'green' : (trend.A === 'SELL' ? 'red' : '')}>
							A: {trend.A}
						</Button>
					</Table.Cell>
					<Table.Cell key={`config_trend_cell_b`}>
						<Button compact onClick={() => setTrend(config.SYMBOL, 'B')} color={trend.B === 'BUY' ? 'green' : (trend.B === 'SELL' ? 'red' : '')}>
							B: {trend.B}
						</Button>
					</Table.Cell>
				</Table.Row>
				{_.map(config, (value, key) => (
					!_.isNil(keys[key]) ? (<Table.Row key={`config_${key}_row`}>
						<Table.Cell key={`config_${key}_cell_key`}>{keys[key]}</Table.Cell>
						<Table.Cell key={`config_${key}_cell_value`}>{values[key] ? values[key][value] : value}{hints[key] ? hints[key] : ''}</Table.Cell>
					</Table.Row>) : <></>
				))}
			</Table.Body>
		</Table>
	);
}

export default Config;
