import _ from 'lodash';
import moment from 'moment';

import React from 'react';

import { Menu, Grid, Segment, Label, Button, Modal, Table } from 'semantic-ui-react';

import Symbols from './Symbols.js';
import { socket } from '../services/socket.js';

import { Usdt } from '@styled-icons/crypto';
import FormConfig from './FormConfig';
import API from '../services/api';

class Crypto extends React.Component {
	constructor (props) {
		super(props);

		this.socket = socket;
		this.state = {
			actual: [],
			webhooks: [],
			symbol: null,
			wallet: {}
		};
	}

	clearLog = () => {
		this.setState({
			webhooks: []
		});
	};

	addSymbol = (config) => {
		this.setState({
			symbol: {
				...(config ? config : {
					'SYMBOL_ONEWAY_ORDER': '',
					'SYMBOL_LEVERAGE_LEVEL': '5',
					'SYMBOL_LEVERAGE_MODE': '1',
					'POSITION_CHECK_INTERVAL': '1000',
					'POSITION_OPEN_AGAIN': '1',
					'POSITION_CLOSE_BY_PNL': '1',
					'POSITION_CLOSE_PNL': '5',
					'POSITION_CLOSE_TIME': '300',
					'ORDER_TYPE': 'Market',
					'ORDER_AMOUNT_TO_TRADE_IN': 'value',
					'ORDER_AMOUNT_TO_STOP_TRADE_IN': 'value',
					'ORDER_AMOUNT_TO_STOP_TRADE_TP_IN': 'value',
					'ORDER_AMOUNT_TO_STOP_TRADE_SL_IN': 'value',
					'ORDER_AMOUNT_TO_TRADE': '10',
					'ORDER_AMOUNT_TO_STOP_TRADE_TP': '1',
					'ORDER_AMOUNT_TO_STOP_TRADE_SL': '1',
					'SYMBOL': '',
					'TRADE_STATE': '',
					'TRADE_BALANCE_STOP': '25',

					'HACK_PNL_FOR_SET_SL': '1',
					'HACK_PNL_FOR_SET_SL_IN': 'value',
					'HACK_SET_SL': '1',
					'HACK_SET_SL_IN': 'value',
					'HACK_SET_SL_ON': '0',
					'HACK_REC_PNL_FOR_SET_SL': '1',
					'HACK_REC_PNL_FOR_SET_SL_IN': 'value',
					'HACK_REC_SET_SL': '1',
					'HACK_REC_SET_SL_IN': 'value',
					'HACK_REC_SET_SL_ON': '0',
				})
			}
		});
	};

	closeModal = () => {
		this.setState({
			symbol: null
		});
	};

	setTrade = async () => {
		await API.Trade();
	};

	componentDidMount () {
		socket.on('webhook', (msg) => {
			this.setState({
				webhooks: [...this.state.webhooks, msg]
			});
		});
		socket.on('actual', (msg) => {
			this.setState({
				actual: [...msg]
			});
		});
		socket.on('wallet', (msg) => {
			this.setState({
				wallet: { ...msg }
			});
		});
	}

	componentWillUnmount () {
		socket.off('webhook');
		socket.off('actual');
		socket.off('wallet');
	}

	render () {
		const {
			actual,
			webhooks,
			wallet
		} = this.state;

		return (
			<Segment>
				<Grid divided="vertically">
					<Grid.Row columns={2}>
						<Grid.Column width={12}>
							<Symbols selectConfig={this.addSymbol}/>
						</Grid.Column>
						<Grid.Column width={4} textAlign="right">
							<Menu key={`wallet`} fluid vertical>
								<Menu.Item active={true} color={'green'}>${wallet.availableToWithdraw}</Menu.Item>
								<Menu.Item>${wallet.walletBalance}</Menu.Item>
								<Menu.Item>${wallet.cumRealisedPnl}</Menu.Item>
								<Menu.Item>${wallet.unrealisedPnl}</Menu.Item>
							</Menu>
							<Table key={`actual`} basic="very" celled collapsing>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Pair</Table.HeaderCell>
										<Table.HeaderCell>Price</Table.HeaderCell>
										<Table.HeaderCell>Percent</Table.HeaderCell>
										<Table.HeaderCell>Volume</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(actual, pair => (
										<Table.Row key={`actual_${pair.symbol}`} active={pair.inTrade ? true : false} color={pair.inTrade ? 'orange' : 'black'}>
											<Table.Cell>{pair.symbol}</Table.Cell>
											<Table.Cell>{pair.price}</Table.Cell>
											<Table.Cell>{pair.percentage} %</Table.Cell>
											<Table.Cell>{pair.amount}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
							<Segment.Group>
								<Segment>
									<Button
										compact
										size="small"
										floated="right"
										onClick={this.setTrade}
										icon={'dollar'}
									/>
									<Button
										compact
										size="small"
										floated="left"
										onClick={this.clearLog}
										icon={'trash'}
									/>
									<Button
										compact
										size="small"
										floated="right"
										onClick={() => this.addSymbol()}
										icon={'add'}
									/>
									Webhook Log <Label circular>{webhooks.length}</Label>
								</Segment>
								<Segment secondary>
							  <pre>
								{webhooks.map((log, i) => (
									<div key={moment(log.time).valueOf()}>{moment(log.time).format('HH:mm:ss')} - {log.symbol} - {log.action} - {log.vector}</div>
								))}
							  </pre>
								</Segment>
							</Segment.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Modal
					open={!!this.state.symbol}
					size="small"
				>
					<FormConfig closeModal={this.closeModal} config={this.state.symbol}/>
				</Modal>
			</Segment>
		);
	}
}

export default Crypto;
